#file-chosen{
  font-family: sans-serif;
  font-size: 12px;
  margin-right: 2px;
  min-width:80%
}

#file-chosed{
  font-family: sans-serif;
  font-size: 14px;
  margin-right: 2px;
}

#invalid-file-chosen{
  font-family: sans-serif;
  color: red;
  font-size: 14px;
}

.fileName{
  width: 81%;
  border: 1px solid black;
  background-color:#D3D3D3;
}

input[type="file"] {
  width: 207px;
  height: 50px;
  background-color: white;
  border-color: #B8B8B8;
  margin-bottom: 4px;
}
input[type="file"]::-webkit-file-upload-button{
  height:50px;
  width: 207px;
  background-color: white;
  border-color: #B8B8B8;
  margin-bottom: 4px;
  }

#servicedate{
width: 95%;
padding: 14px;
border: "10%";
}