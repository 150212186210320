.MessageYou {
  background-color: #3d6e8f;
  font-size: 13px;
  border-radius: 7.5px;
  padding: 6px 7px 8px 9px;
  color: white !important;
  position: relative;
  margin-left: 2%;
  margin-bottom: 3px;
  align-self: flex-start;
}

.MessageMe {
  background-color: #59677e;
  font-size: 13px;
  border-radius: 7.5px;
  padding: 6px 7px 8px 9px;
  color: rgba(241, 241, 242, 0.92) !important;
  position: relative;
  margin-right: 2%;
  margin-bottom: 3px;
  align-self: flex-end;
}

.MessageMeBid {
  background-color:#8963a7;
  font-size: 13px;
  border-radius: 7.5px;
  padding: 6px 7px 8px 9px;
  color: rgba(241, 241, 242, 0.92) !important;
  position: relative;
  margin-right: 2%;
  margin-bottom: 3px;
  align-self: flex-end;
}

.MessageYouBid {
  background-color:#8963a7;
  font-size: 13px;
  border-radius: 7.5px;
  padding: 6px 7px 8px 9px;
  color: rgba(241, 241, 242, 0.92) !important;
  position: relative;
  margin-left: 2%;
  margin-bottom: 3px;
  align-self: flex-start;
}

.OwnUser {
  text-align: left;
  color: rgb(255 255 255) !important;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 22px;
}
.User {
  text-align: left;
  color: white !important;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 22px;
}

.Date {
  font-size: 10px;
  margin-left: 10px;
  margin-top: 20px;
  position: relative;
  color: rgba(241, 241, 242, 0.63) !important;
  float: right;
  margin: 10px 0 -5px 8px;
}