.GridStyle {
    width: 100%;
    height: 5%;
}

.RowStyle {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

#center {
    position: relative;
    z-index: 1;
    width:80%;
    margin-bottom: 10.5%;
    margin-top: 8.2%;
}

.rc-anchor-normal {
    width: 200% !important;
    margin-left: 10% !important;
}