.wpo-footer-area{
    background: #142440;
  }

  .wpo-footer-top{
    padding: 60px 0;
  }
  .wpo-footer-logo{
    margin-bottom: 30px;
  }
  .social li a {
      display: block;
      height: 45px;
      width: 45px;
      background: #fff;
      color: #333;
      line-height: 45px;
      border-radius: 50%;
      text-align: center;
      margin-right: 10px;
      transition: all .3s;
      margin-top: 5px;
  }
  .social li a:hover{
    background:#34ccff;
    color:#fff;
  }
  .wpo-footer-top p {
      color: #fff;
      margin-bottom: 30px;
  }
  .wpo-footer-top a{
    color:#fff;
  }
  .Recent-News-area p{
    margin-bottom: 10px;
  }
  .Recent-News-area span{
    color:#bbb; 
  }
  .Recent-News-area span i{
    padding-right: 10px;
    color: #34ccff;
  }
  .resent-sub{
    margin-bottom: 35px;
  }
  .instagram ul {
      flex-wrap: wrap;
      margin: 0px -3px;
  }
  
  .instagram ul li {
      -ms-flex: 0 0 33.33%;
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin-bottom: 5px;
      padding: 0px 3px;
  }
  
  .instagram ul li img {
      width: 100%;
  }
  .footer-widget.instagram{
    margin-top: 0;
  }
  .footer-widget.instagram h3{
    font-size: 25px;
    margin-bottom: 30px;
  }
  .footer-link ul li a{
    padding-top: 10px;
    display: block;
  }
  .wpo-footer-top h3{
    font-size: 25px;
    color:#fff;
    margin-bottom: 20px;
  }
  
  .wpo-footer-bottom{
    background: #101e35;
    position:fixed;
    height: 75px;
    bottom:0;
    width:100%;
    z-index: 1;
  }
  .container{
    display:inline;
  }
  .wpo-footer-bottom-content {
    position: relative;
  }
  .wpo-footer-bottom-content span{
    color:#fff;
    font-size: 16px;
    position: relative;
    text-align: right;
    left: 10%;

  }