.Header {
    background-color:#0d2346;
    height: 59px;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .HeaderLeft {
    display: block;
    cursor: pointer;
    align-items: left;
  }
  .HeaderRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 13vw;
  }
  .icon {
    cursor: pointer;
    padding: 8px;
    height: 24px;
    width: 24px;
    margin-left: 10px;
  }
  
  .icon:active {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  .icon:first-of-type {
    margin-left: 0px;
  }
  