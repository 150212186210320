.Home {
  display: flex;
}

@media (min-width: 570px) and (max-width:820px) {
  .loadexp{
    display:block !important;
  }
}

/* counter offer style */

.ButtonColorRed {
  border: 1px solid #c90e0e !important;
  color: #c90e0e !important;
  width:max-content;
}

.ButtonColorGreen {
  border: 1px solid #0ee53d !important;
  color: 1px solid #0ee53d !important;
  width:max-content;
}

.counteroffersubmit-btn{
  border: 2px solid green;
  border-radius: 5px;
  padding: 5px 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;
  margin-right:3%;
}
.counteroffersubmit-btn:hover{
  background-color: green;
  color:white;
}
.counteroffersubmit-btn:focus{
  outline: none !important;
}
.counteroffercancel-btn:hover{
  background-color: red;
  color:white;
}
.counteroffercancel-btn:focus{
  outline: none !important;
}
.counteroffercancel-btn{
  border: 2px solid red;
  border-radius: 5px;
  padding: 5px 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;

}
.counterofferaccept-btn:hover{
  background-color: orange;
  color:white;
}
.counterofferaccept-btn:focus{
  outline: none !important;
}
.counterofferaccept-btn{
  border: 2px solid orange;
  border-radius: 5px;
  width:max-content;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;
}
 
.afterBidDisplay {
  margin-bottom: 0px !important;
  font-size: 14px !important;
  color:#101e35;
}

.Color {
  background-color: rgb(232 240 254);
}

.heightfix
{
  width: 100%;
  background-color: #ececec;
  height: 154px;
}
.h6{
color: #101e35;
}