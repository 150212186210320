
  .form-control {
    height: 38px !important;
    width: 100% !important;
    border: 0px !important;
    box-shadow: none !important;
  }
  .react-tel-input {
    height: 40px !important;
    width: 84% !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    box-shadow: none !important;
  }
  
  .flag-dropdown {
    border: 0px !important;
  }

  input[type="checkbox"][readonly] {
  pointer-events: none;
}
  